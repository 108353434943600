import './App.css';
import {Fragment, useEffect, useState} from "react";
import {fakerFI as faker} from '@faker-js/faker';
import {Route, Routes, useNavigate, useParams} from "react-router-dom";
import {
    createOrder,
    getOrder,
    getPaymentMethods,
    setPaymentMethodInCheckout,
    refundOrder,
    captureOrder,
    cancelOrder,
    submitPaymentDetails
} from "./ssc/webstore";
import {Button, Card, Container, Form, InputGroup, Table} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import {setupPaymentComponent, Environment} from "@sok-digikehitys/ssc-ecom-sdk"

const PaymentComponent = ({locale, checkoutResponse, orderId, shopperInfo}) => {
    const domId = "payment-component"

    useEffect(() => {
        let paymentComponent = undefined;
        if (checkoutResponse !== undefined) {
            const paymentMethodOptions = {
                card: {
                    hasHolderName: true,
                    holderNameRequired: true,
                },
            }
            const submitCheckoutDetailsFunction = (details) => {
                return submitPaymentDetails(orderId, {
                    ...details,
                    shopperInfo
                })
            }
            setupPaymentComponent('#' + domId, locale, checkoutResponse, submitCheckoutDetailsFunction, paymentMethodOptions, Environment.development).then(component => {
                paymentComponent = component
            })
        }
        return () => {
            if (paymentComponent !== undefined) {
                paymentComponent.unmount()
            }
        }
    }, [locale, checkoutResponse, orderId, shopperInfo])

    return (
        <div>
            <div id={domId} style={{"padding": 10}}></div>
        </div>
    )
}
const PaymentContainer = ({paymentMethod, orderId, shopperInfo}) => {
    const [locale, setLocale] = useState("fi-FI")
    const [checkoutResponse, setCheckoutResponse] = useState()
    const onChangeLocale = (event) => {
        const value = event.target.value
        setLocale(value)
    }
    useEffect(() => {
        if (paymentMethod !== undefined) {
            setPaymentMethodInCheckout(orderId, paymentMethod, locale).then(r => {
                setCheckoutResponse(r);
            })
        }
    }, [orderId, paymentMethod, locale])

    return <Container>
        <br/>
        Locale: <select onChange={onChangeLocale}>
        <option value={"fi-FI"}>fi-FI</option>
        <option value={"sv-SE"}>sv-SE</option>
        <option value={"en-EN"}>en-EN</option>
    </select>
        <PaymentComponent checkoutResponse={checkoutResponse} orderId={orderId} locale={locale} shopperInfo={shopperInfo}/>
    </Container>

}


const ShopperInfoComponent = ({shopperInfo, setShopperInfo}) => {

    return (
        <Container>
            <Form>
                <h3>Shopper info</h3>
                <EditableShopperInfo shopperInfo={shopperInfo} setShopperInfo={setShopperInfo}/>
            </Form>
        </Container>
    )
}

const Methods = ({methods, setPaymentMethod}) => {

    const selectPaymentMethod = (e) => {
        setPaymentMethod(e.target.value)
    }

    return (
        <Container>
            <h1>Example checkout page</h1>
            <p>
                This would contain all the form fields for entering shipping address etc.
            </p>
            <p>
                After all basic information, there would be the selection of payment method:
            </p>
            {methods.map((method) => (
                <div key={method.id}>
                    <input type={"radio"} id={method.id} name="paymentMethod" value={method.id}
                           onChange={selectPaymentMethod}/>
                    <label htmlFor={method.id}>{method.name}</label>
                </div>
            ))
            }
        </Container>
    )
}

const PaymentNotificationEntry = ({entry}) => {
    const data = entry.data
    const type = entry.data.type

    if (type === "webhook") {
        return (
            <tr>
                <td valign={"top"}>{entry.timestamp}</td>
                <td valign={"top"}>WEBHOOK</td>
                <td colSpan={2}>
                    <pre>{JSON.stringify(data.notification, null, 4)}</pre>
                </td>
            </tr>
        )
    }
    if (type === "api") {
        console.log(entry.request)
        const fmt = (s) => s !== "" ? JSON.stringify(JSON.parse(s), null, 4) : ""

        return (
            <Fragment>
                <tr>
                    <td rowSpan={4} valign={"top"}>{entry.timestamp}</td>
                    <td rowSpan={4} valign={"top"}>API</td>
                    <td>URL</td>
                    <td>
                        <tt>{data.url}</tt><br/>
                    </td>
                </tr>
                <tr>
                    <td>Request</td>
                    <td>
                        <pre>{fmt(data.request)}</pre>
                    </td>
                </tr>
                <tr>
                    <td>Response status</td>
                    <td>{data.responseStatusCode}</td>
                </tr>
                <tr>
                    <td>Response</td>
                    <td>
                        <pre>{fmt(data.response)}</pre>
                    </td>
                </tr>
            </Fragment>
        )
    }
    /*
                        <pre>{JSON.stringify(JSON.parse(entry.request), null, 4)}</pre>
                    <br/>
                    HTTP {entry.responseCode}:<br/>
                    <pre>{JSON.stringify(JSON.parse(entry.response), null, 4)}</pre>

     */
    return <tr>
        <td>???</td>
        <td>
            <pre>{JSON.stringify(entry)}</pre>
        </td>
    </tr>
}

const Refund = ({order, fetchOrder}) => {
    const [refundAmount, setRefundAmount] = useState({value: 0, currency_code: order.amount.currency_code})
    if ((order.status !== 'paid') && (order.status !== 'refunded_partially') && (order.status !== 'refund_failed'))
        return (<></>)

    const refund = () => {
        refundOrder(order.orderId, refundAmount).then(r => fetchOrder(order.orderId))
    }

    const onChangeValue = (event) => {
        const value = parseInt(event.target.value)
        setRefundAmount({value, currency_code: order.amount.currency_code})
    }

    return (
        <Form>
            <Form.Group className="mb-3">
                <Form.Label>Refund value</Form.Label>
                <InputGroup className="mb-3">
                    <Form.Control onChange={onChangeValue} type={"text"} value={refundAmount.value}/>
                    <InputGroup.Text>{refundAmount.currency_code} minor units</InputGroup.Text>
                </InputGroup>


            </Form.Group>
            <Button className="mb-3" onClick={refund}>Refund</Button>
        </Form>
    )
}

const CaptureOrCancel = ({order, fetchOrder}) => {
    const [captureAmount, setCaptureAmount] = useState({value: 0, currency_code: order.amount.currency_code})
    if ((order.status !== 'preauthorized'))
        return (<></>)

    const capture = () => {
        captureOrder(order.orderId, captureAmount).then(r => fetchOrder(order.orderId))
    }

    const cancel = () => {
        cancelOrder(order.orderId).then(r => fetchOrder(order.orderId))
    }

    const onChangeValue = (event) => {
        const value = parseInt(event.target.value)
        setCaptureAmount({value, currency_code: order.amount.currency_code})
    }

    return (
        <Form>
            <Form.Group className="mb-3">
                <Form.Label>Capture value</Form.Label>
                <InputGroup className="mb-3">
                    <Form.Control onChange={onChangeValue} type={"text"} value={captureAmount.value}/>
                    <InputGroup.Text>{captureAmount.currency_code} minor units</InputGroup.Text>
                </InputGroup>


            </Form.Group>
            <Button className="mb-3" onClick={capture}>Capture</Button>
            <div style={{"padding": 10}}></div>
            <Button className="mb-3" onClick={cancel}>Cancel</Button>
        </Form>
    )
}

const LinkToPsp = ({checkout}) => {
    if (checkout === null) return (<></>)
    const pspReference = checkout.psp_reference
    if (pspReference === "UNDEFINED") return (<></>)


    const url = `https://ca-test.adyen.com/ca/ca/accounts/showTx.shtml?pspReference=${pspReference}&txType=Payment`
    return (
        <div>View transaction in PSP: <a href={url} target={"_new"}>{pspReference}</a></div>
    )
}

const PaymentCompleteView = () => {
    const {orderId} = useParams()
    const [data, setData] = useState(undefined)

    const fetchOrder = (orderId) => {
        setData(undefined)
        getOrder(orderId).then((result) => {
            setData(result)
        })
    }

    useEffect(() => {
        fetchOrder(orderId)
    }, [orderId])

    if (data === undefined) return (
        <Container>
            <h1>Fetching order details...</h1>
        </Container>
    )

    const orderEvents = [...data.order_notifications].sort((a, b) => (a.timestamp.localeCompare(b.timestamp)))

    return (
        <Container>
            <h1>Order #{orderId}</h1>
            <Refund order={data.order} fetchOrder={fetchOrder}/>
            <CaptureOrCancel order={data.order} fetchOrder={fetchOrder}/>
            <h2>Order:</h2>
            <pre>
                {JSON.stringify(data.order, null, 4)}
            </pre>
            <LinkToPsp checkout={data.checkout}/>
            <h2>Checkout:</h2>
            <pre>
                {JSON.stringify(data.checkout, null, 4)}
            </pre>
            <h2>Events:</h2>
            <Table striped bordered hover>
                <tbody>
                {orderEvents.map((entry) => (
                    <PaymentNotificationEntry key={entry.id} entry={entry}/>
                ))}
                </tbody>
            </Table>
        </Container>
    )
}


const PaymentView = () => {
    const [state, setState] = useState("init")
    const [availableMethods, setAvailableMethods] = useState([])
    const [shopperInfo, setShopperInfo] = useState({
        "email": faker.internet.email(),
        "phone": "+358400000001", // todo: use faker.phone.number({style: 'international'}) when fakerjs v9 released
        "ip_address": faker.internet.ipv4(),
    })

    const [paymentMethod, setPaymentMethod] = useState(undefined)
    const {orderId} = useParams()

    useEffect(() => {
        setState("loading")
        getPaymentMethods(orderId).then((result) => {
            setState("loaded")
            setAvailableMethods(result.payment_methods)
        })
    }, [orderId])

    if (state === "init") {
        return (<div>Initializing...</div>)
    }
    if (state === "loading") {
        return (<div>Querying payment methods...</div>)
    }
    return (
        <Container>
            <Methods methods={availableMethods} setPaymentMethod={setPaymentMethod}/>
            <PaymentContainer paymentMethod={paymentMethod} orderId={orderId} shopperInfo={shopperInfo}/>
            <ShopperInfoComponent shopperInfo={shopperInfo} setShopperInfo={setShopperInfo}/>
        </Container>
    )
}

const EditableRow = ({setCheckout, checkout, field}) => {
    const onChange = (event) => {
        const n = {...checkout}
        n[field] = event.target.value
        setCheckout(n)
    }

    return (
        <Form.Group className="mb-3">
            <Form.Label>{field}</Form.Label>
            <Form.Control onChange={onChange} type={"text"} value={checkout[field]}/>
        </Form.Group>
    )
}

const EditableCheckBox = ({setCheckout, checkout, field, label, value}) => {
    const onChange = (event) => {
        const n = {...checkout}
        n[field] = event.target.checked === true ? value : null
        setCheckout(n)
    }

    return (
        <Form.Group className="mb-3">
            <Form.Check onChange={onChange} type={"checkbox"} label={label} checked={checkout[field] === value}  value={checkout[field]}/>
        </Form.Group>
    )
}

const EditableAmount = ({setCheckout, checkout}) => {
    const onChangeValue = (event) => {
        const n = {
            ...checkout,
            amount: {value: parseInt(event.target.value), currency_code: checkout.amount.currency_code}
        }
        setCheckout(n)
    }
    const onChangeCurrencyCode = (event) => {
        const n = {...checkout, amount: {currency_code: parseInt(event.target.value), value: checkout.amount.value}}
        setCheckout(n)
    }

    return (
        <>
            <Form.Group className="mb-3">
                <Form.Label>amount.value</Form.Label>
                <Form.Control onChange={onChangeValue} type={"text"} value={checkout.amount.value}/>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>amount.currency_code</Form.Label>
                <Form.Control onChange={onChangeCurrencyCode} type={"text"} value={checkout.amount.currency_code}/>
            </Form.Group>
        </>
    )
}

const EditableShopperInfo = ({shopperInfo, setShopperInfo}) => {
    const onChangeEmail = (event) => {
        setShopperInfo(prevState => ({...prevState, email: event.target.value}));
    }
    const onChangePhone = (event) => {
        setShopperInfo(prevState => ({...prevState, phone: event.target.value}));
    }
    const onChangeIPAddress = (event) => {
        setShopperInfo(prevState => ({...prevState, ip_address: event.target.value}));
    }

    return (
        <>
            <Form.Group className="mb-3">
                <Form.Label>shopperInfo.email</Form.Label>
                <Form.Control onChange={onChangeEmail} type={"text"} value={shopperInfo.email}/>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>shopperInfo.phone</Form.Label>
                <Form.Control onChange={onChangePhone} type={"text"} value={shopperInfo.phone}/>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>shopperInfo.ip_address</Form.Label>
                <Form.Control onChange={onChangeIPAddress} type={"text"} value={shopperInfo.ip_address}/>
            </Form.Group>
        </>
    )
}


const EditCheckout = () => {
    const [state, setState] = useState("edit")
    const [checkout, setCheckout] = useState({
        "reference": `order ${new Date().toISOString().replace(/[:.]/g, '_')}`,
        "amount": {
            "value": 15000,
            "currency_code": "EUR"
        },
        "operation": "purchase",
        "token_processing_model": null,
    })

    const navigate = useNavigate();

    const moveToCheckout = () => {
        setState("creating")
        createOrder(checkout).then((result) => {
            setState("created")
            navigate(`/order/${result.order_id}`)
        })
    }
    if (state === "created") {
        return (
            <Card><h1>Order created, redirecting ...</h1></Card>
        )
    }
    if (state === "creating") {
        return (
            <Card>
                <h1>Creating order, please wait ...</h1>
            </Card>
        )
    }

    return (
        <Form>
            <h2>Create order</h2>
            <EditableAmount checkout={checkout} setCheckout={setCheckout}/>
            <EditableRow checkout={checkout} setCheckout={setCheckout} field="reference"/>
            <EditableRow checkout={checkout} setCheckout={setCheckout} field="operation"/>
            <EditableCheckBox checkout={checkout} setCheckout={setCheckout} field="token_processing_model" label="Tokenize during purchase" value="MitToken"/>
            <Button variant={"primary"} type={"submit"} onClick={moveToCheckout}>
                Checkout &gt;
            </Button>
        </Form>
    )
}

const App = () => {
    return (
        <Container>
            <Routes>
                <Route path={"/"} element={<EditCheckout/>}/>
                <Route path={"/order/:orderId"} element={<PaymentView/>}/>
                <Route path={"/completed/:orderId"} element={<PaymentCompleteView/>}/>
            </Routes>
        </Container>
    );
}

export default App;
